body {
  background-color: rgba(255, 255, 255, 1);
  font-family: "Roboto", sans-serif !important;
  margin: 0px !important;
  height: 100% !important;
}

.whitecolortypo {
  color: #ffffff !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 bold !important;
  letter-spacing: 0em !important;
}
.whitecolortypo1 {
  color: #ffffff !important;
  font-family: "Calibri", sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 0em !important;
}
.whitecolortypo2 {
  font-family: "Poppins" sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 0.25em !important;
  color: rgba(255, 255, 255, 1) !important;
}
.whitecolortypopopins {
  color: #ffffff !important;
  font-weight: 700 !important;
  font-family: "Poppins" sans-serif !important;
}
.lightgreytypo {
  color: rgba(210, 210, 210, 1) !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
}
.greycolortypo {
  color: #898a97 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 700 bold !important;
}
.blackcolortyp2 {
  color: rgba(5, 37, 76, 1) !important;
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
}
.blackcolortypoCal {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 700 !important;
  font-family: "  ", sans-serif !important;
}
.blackcolortypoCal-400 {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
}
.skytypo {
  color: rgba(156, 169, 197, 1) !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
}
.skytypo700 {
  color: rgba(156, 169, 197, 1) !important;
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
}
.skybluetypo700 {
  color: rgba(185, 199, 240, 1) !important;
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
}
.bluecolortypo {
  color: rgba(5, 37, 76, 1) !important;
  font-weight: 700 !important;
  font-family: "Poppins" sans-serif !important;
}
.bluecolortypo-bold {
  color: rgba(5, 37, 76, 1) !important;
  font-weight: 600 !important;
  font-family: "Poppins" bold !important;
}

.bluecolortypopopins {
  color: rgba(5, 37, 76, 1) !important;
  font-weight: 700 !important;
  font-family: "Poppins" sans-serif !important;
}

.buttonstylep {
  color: rgba(5, 37, 76, 1) !important;
  background: radial-gradient(
    144.28% 144.28% at -16.83% -15.93%,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border: 2px solid rgba(5, 37, 76, 1) !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  letter-spacing: 0.25em !important;
  text-align: left !important;
  text-transform: capitalize !important;
  border-radius: 15px !important;
  padding: 9px 15px !important;
}
.buttonstylepsm {
  color: rgba(5, 37, 76, 1) !important;
  background: radial-gradient(
    144.28% 144.28% at -16.83% -15.93%,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border: 2px solid rgba(5, 37, 76, 1) !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  line-height: 14px !important;
  letter-spacing: 0.25em !important;
  text-align: left !important;
  text-transform: capitalize !important;
  border-radius: 15px !important;
  padding: 2px 13px !important;
}
.whitebutton {
  color: rgba(255, 255, 255, 9) !important;
  background: radial-gradient(
    144.28% 144.28% at -16.83% -15.93%,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.1) 100%
  ) !important;
  text-transform: capitalize !important;
}
.backGcolor {
  background-color: #05254c;
}
.NextButton {
  border-radius: 12px !important;
  padding: 3px 14px !important;
}
.ArrowIconWhite {
  font-size: 1.7rem !important;
  color: rgba(255, 255, 255, 9) !important;
}
.appbar {
  background: rgba(5, 37, 76, 1) !important;
}
img {
  width: 100%;
  height: 100%;
}
.width-100 {
  width: 100% !important;
}
.width-75 {
  width: 75% !important;
  margin-right: auto !important;
}
.width-30 {
  width: 30% !important;
}
.width50 {
  width: 50% !important;
}
.width40 {
  width: 35% !important;
}
.width-70 {
  width: 70% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.width-50 {
  width: 50% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.width50 {
  width: 50% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.width-88 {
  width: 88% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.width-92 {
  width: 92% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
/* Letter Spacing */

.fs-11px {
  font-size: 11px !important;
}
.fs-13px {
  font-size: 13px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.fs-20px {
  font-size: 1.5rem !important;
}
.fs-22px {
  font-size: 20px !important;
}
.fs-25px {
  font-size: 25px !important;
}
.fs-26px {
  font-size: 26px !important;
}
.fs-32px {
  font-size: 32px !important;
}
.fs18px {
  font-size: 18px !important;
}
.ls-2 {
  letter-spacing: 2px !important;
}
.ls-6 {
  letter-spacing: 6px !important;
}
.ls-10 {
  letter-spacing: 10px !important;
}
/* Padding Size-s */
.padding-30 {
  padding: 30px !important;
}
.padding-60 {
  padding: 60px !important;
}
/* padding Top */
.p-t10 {
  padding-top: 10px !important;
}
.p-t20 {
  padding-top: 20px !important;
}
.p-t30 {
  padding-top: 30px !important;
}
.p-t60 {
  padding-top: 60px !important;
}
.p-t40 {
  padding-top: 40px !important;
}
.p-t80 {
  padding-top: 80px !important;
}
.p-t10 {
  padding-top: 10px !important;
}
.pt10 {
  padding-top: 10% !important;
}
.p-t5 {
  padding-top: 5px !important;
}
/* padding Bottom */
.p-b10 {
  padding-bottom: 10px !important;
}
.p-b20 {
  padding-bottom: 20px !important;
}
.p-b60 {
  padding-bottom: 60px !important;
}
/* padding Left */
.pl-10 {
  padding-left: 10px !important;
}
.p-l30 {
  padding-left: 30px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
/* padding Right */
.pr-10 {
  padding-right: 10px !important;
}
.p-r60 {
  padding-right: 60px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pt20-pr80 {
  padding-top: 20px !important;
  padding-right: 80px !important;
}
.pt20-pr80 {
  padding-top: 20px !important;
  padding-right: 80px !important;
}
.pt30-pl40 {
  padding-top: 30px !important;
  padding-left: 40px !important;
}
.pt20-pr60 {
  padding-top: 20px !important;
  padding-right: 60px !important;
}
.p-t60p-l30 {
  padding-top: 60px !important;
  padding-left: 30px !important;
}
/* Margin Size-s */
.m30-0 {
  margin: 30px 0px !important;
}
.m-50 {
  margin: 50px 0px !important;
}
/* Margin Top */
.mt-5px {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mt-150 {
  margin-top: 150px !important;
}
.mt--90 {
  margin-top: -90px !important;
}
/* Margin Bottom */
.mb-30 {
  margin-top: 30px !important;
}
.mb-40 {
  margin-top: 40px !important;
}
.scrollicon {
  background-color: #05254c !important;
  color: #fff !important;
  left: 10px !important;
}
.my-element {
  display: inline-block !important;
  animation: fadeInLeftBig !important;
  animation-duration: 5s !important;
}
.dilaogcontainer {
  transition: none !important;
}
.whatsapp-button {
  position: fixed;
  bottom: 90px;
  z-index: 1111;
  right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out;
}
.whatsapp-button:hover {
  transform: scale(1.1);
}
.whatsapp-button:hover .whatsapp-text {
  display: block;
  opacity: 1;
}
.whatsapp-button-sm {
  position: fixed;
  bottom: 90px;
  z-index: 1111;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out;
}
.whatsapp-button-sm:hover {
  transform: scale(1.1);
}
.whatsapp-button-sm:hover .whatsapp-text {
  display: block;
  opacity: 1;
}
.whatsapp-icon {
  color: white;
  font-size: 24px;
}
.whatsapp-text {
  display: none;
  position: absolute;
  top: -25px;
  right: 0;
  width: 130px;
  padding: 5px;
  background-color: #25d366;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.hamburger-menu-button {
  width: 20px !important;
  height: 20px !important;
  padding: 9px !important;
  display: block !important;
  position: relative !important;
  margin: 10px 0px !important;
  z-index: 100 !important;
  background: #05254c !important;
  border: 5px solid white !important;
  box-sizing: content-box !important;
  border-radius: 50% !important;
  text-indent: 100% !important;
  color: transparent !important;
  white-space: nowrap !important;
  cursor: pointer !important;
  overflow: hidden !important;
  outline: 0 !important;
}
.hamburger-menu-button-open {
  top: 50% !important;
  margin-top: -1px !important;
  left: 50% !important;
  margin-left: -12px !important;
}
.hamburger-menu-button-open,
.hamburger-menu-button-open::before,
.hamburger-menu-button-open::after {
  position: absolute !important;

  width: 24px !important;

  height: 2px !important;
  background: #fff !important;
  border-radius: 4px !important;
  -webkit-transition: all 0.3s !important;
  transition: all 0.3s !important;
}
.hamburger-menu-button-open::before,
.hamburger-menu-button-open::after {
  left: 0 !important;
  content: "" !important;
}
.hamburger-menu-button-open::before {
  top: 6px !important;
}
.hamburger-menu-button-open::after {
  bottom: 6px !important;
}
.hamburger-menu-button-close {
  background: transparent !important;
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.hamburger-menu-button-close::before {
  -webkit-transform: translateY(-6px) rotate(45deg) !important;
  transform: translateY(-6px) rotate(45deg) !important;
}
.hamburger-menu-button-close::after {
  -webkit-transform: translateY(6px) rotate(-45deg) !important;
  transform: translateY(6px) rotate(-45deg) !important;
}
.ham-menu {
  position: absolute !important;
  top: 42px !important;
  left: 48px !important;
  margin: auto !important;
  max-width: 570px !important;
  overflow: hidden !important;
}
.box-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}
/* footer component  */
.Footer {
  display: flex !important;
  position: relative !important;
  width: 100%;
  justify-content: flex-end !important;
}
.fbicon {
  color: #1e90ff !important;
}
.youtubeicon {
  color: red !important;
}
.footericon {
  /* background-color: #ffffff !important; */
  border-radius: 15px !important;
}
.whatcolor {
  background-color: #25d366 !important;
  color: #ffffff !important;
  border-radius: 5px !important;
}

/* Home Component */
.gridbckcolr {
  background-image: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 0.54) 10%,
    rgba(255, 255, 255, 0.54) 100%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0.18) 100%
  ) !important;
  border: 1.22px solid #fff !important;
  background-color: rgb(227, 229, 235) !important;
}
.homeitem1 {
  position: absolute !important;
  bottom: 50% !important;
  left: 25% !important;
}
.homeitem2 {
  position: absolute !important;
  bottom: 52% !important;
  left: 28% !important;
}
.homeitem-btn-store {
  position: absolute !important;
  bottom: 3% !important;
  left: 27% !important;
}
.homeitem-btn-store-sm {
  position: absolute !important;
  bottom: 0% !important;
  left: 10% !important;
}

.homeitem-3 {
  padding: 20px !important;
}
.homeitem-3typo {
  margin-top: 60px !important;
  padding-right: 30px !important;
}

.whycynetixbtn {
  border: 2px solid rgba(255, 255, 255, 1) !important;
  border-radius: 15px !important;
  padding: 4px 17px !important;
  text-transform: capitalize !important;
  direction: row !important;
  justify-content: center !important;
  align-items: flex-end !important;
  display: flex !important;
  color: #05254c !important;
  letter-spacing: 0.25em !important;
  font-weight: 600 !important;
}
.whycynetixbtnsm {
  border: 2px solid rgba(255, 255, 255, 1) !important;
  border-radius: 15px !important;
  padding: 4px 12px !important;
  text-transform: capitalize !important;
  direction: row !important;
  justify-content: center !important;
  align-items: flex-end !important;
  display: flex !important;
  color: #05254c !important;
  letter-spacing: 0.25em !important;
  font-weight: 600 !important;
}
.homecontainer-4 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.home-div-img-4 {
  position: relative !important;
  width: 80% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.home-div-4-1 {
  position: absolute !important;
  top: 37% !important;
  left: 1.3% !important;
  width: 21% !important;
  height: 5vh !important;
}
.home-div-4-2 {
  position: absolute !important;
  right: 1% !important;
  top: 36.6% !important;
  width: 21% !important;
  height: 5vh !important;
}
.home-div-4-3 {
  position: absolute !important;
  bottom: 1% !important;
  left: 1.4% !important;
  width: 21% !important;
  height: 5vh !important;
}
.home-div-4-4 {
  position: absolute !important;
  bottom: 1% !important;
  right: 1% !important;
  width: 21% !important;
  height: 5vh !important;
}
/* .homecontrainer1 {
  height: 500px !important;
  position: absolute !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 5px !important;
  background-image: linear-gradient(
    to top,
    #ffffff 0%,
    #ffffff 100%
  ) !important;
  overflow: hidden !important;
} */

.wave {
  width: 150% !important;
  height: 1000px !important;
  position: absolute !important;
  top: -25% !important;
  /* left: 0% !important; */
  margin-left: -500px !important;
  margin-top: -500px !important;
  border-radius: 45% !important;
  background: rgba(5, 37, 76, 1) !important;
}
.homeimg {
  background-image: url(../Img/homeImg4.png);
}

.joinusb {
  box-shadow: -4px 5px 5px 0px #ffffff40 inset !important;
  box-shadow: 4px -4px 5px 0px #ffffff40 inset !important;
  background: linear-gradient(
    143.93deg,
    rgba(255, 255, 255, 0.6) -98.98%,
    rgba(255, 255, 255, 0.36) -98.96%,
    rgba(255, 255, 255, 0) 100.64%,
    rgba(255, 255, 255, 0.06) 100.64%
  ) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 15px !important;
  padding: 1.8% 15% !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.whycynetixgridnoborder {
  background-color: rgb(227, 229, 235) !important;
}

/* Room Dialog Style */
.buttoncontainer {
  position: relative;
}
.btn {
  background: none;
}
.btn:active {
  border-color: none !important;
}

.btn {
  position: absolute;
  /* transform: translate(-50%, -50%); */
  background-color: transparent;
  color: black;
  font-size: 1rem;
  /* border: 1px solid red !important; */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 100% !important;
  height: 5vh;
}

.roombuttonstyle {
  display: inline-block !important;
  padding: 0.9% 37% !important;
  border: 0.2em solid #ffffff !important;
  /* margin: 0 0.3em 0.3em 0 !important; */
  border-radius: 0.3em !important;
  box-sizing: border-box !important;
  color: black !important;
  text-align: center !important;
  transition: all 0.2s !important;
}
.roomcontainer {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 92% !important;
}

.dialoglistitemstylemd {
  margin-top: 170px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.dialoglistitemstylesm {
  margin: 20px 0px !important;
}
.itemlist {
  border: 1px solid #ffffff !important;
  padding: 20px !important;
}
.dynamicsdialogimgmd {
  padding: 10px 40px !important;
}
.dynamicsdialogimgsm {
  padding: 10px 15px !important;
}
.dialog-action {
  justify-content: space-between !important;
  width: 100% !important;
  margin-bottom: 20px !important;
  padding: 0px 0px !important;
}
.dialog-action-pre {
  justify-content: flex-end !important;
  width: 100% !important;
  margin-bottom: 20px !important;
  padding: 0px 0px !important;
}
/* Product  Component */
.productpanelgrid {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  background: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 0.54) 0%,
    rgba(255, 255, 255, 0.54) 0.01%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0.18) 100%
  ) !important;
  border: 1.22px solid rgba(255, 255, 255, 1) !important;
  margin: 10% 0% !important;
  padding: 5% 0% !important;
  width: 82% !important;
}
.productpanelgridsm {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  background: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 0.54) 0%,
    rgba(255, 255, 255, 0.54) 0.01%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0.18) 100%
  ) !important;
  border: 1.22px solid rgba(255, 255, 255, 1) !important;
  margin: 10% 0% !important;
  padding: 5% 0% !important;
  width: 86% !important;
}
.productimg1 {
  width: 80% !important;
}
.productimg {
  width: 43.5% !important;
}
.roductimg2 {
  width: 40% !important;
}
.productimg3 {
  width: 45% !important;
}
.productimg5 {
  width: 54% !important;
}
.productimg6 {
  width: 54% !important;
}
/* WHY CYNETIX COMPONENT */
.bcheader {
  background: linear-gradient(
    144.45deg,
    #5d93a7 0%,
    #6aa2ac 0.01%,
    #2a6c86 100%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}
.smborder {
  border: 1px solid red !important;
}
.mdborder {
  border: 1px solid green !important;
}
.inputstyle {
  background: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 11) 0%,
    rgba(255, 255, 255, 0.54) 0.01%,
    /* rgba(255, 255, 255, 0) 100%, */ rgba(255, 255, 255, 0.18) 100%
  ) !important;
  color: #ffffff !important;
  width: 80% !important;
  border-radius: 6px !important;
  padding: 6px !important;
  margin-top: 14px !important;
}
.textarea {
  background: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 0.54) 0%,
    rgba(255, 255, 255, 0.54) 0.01%,
    rgba(255, 255, 255, 0.18) 100%
  ) !important;
  color: #d2d2d2 !important;
  width: 77% !important;
  border-radius: 6px !important;
  padding: 6px !important;
  margin-top: 14px !important;
  height: 6em !important;
  border: none !important;
  font-size: 18px !important;
}
.wcsubmitButton {
  color: #fff !important;
  background: radial-gradient(
    144.28% 144.28% at -16.83% -15.93%,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  border: 1px solid #fff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0.25em !important;
  text-align: left !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  padding: 10px 20px !important;
  width: 45% !important;
  margin-top: 15px !important;
}
.wccallusgrid {
  background: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 0.54) 0%,
    rgba(255, 255, 255, 0.54) 0.01%,
    rgba(255, 255, 255, 0.18) 100%
  ) !important;
  z-index: 1111;
}

/*  support  */
.chsgrid {
  background: radial-gradient(
    135.69% 138% at 2.92% 7.3%,
    rgba(255, 255, 255, 0.54) 0%,
    rgba(255, 255, 255, 0.54) 0.01%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0.18) 100%
  ) !important;
  border: 1px solid #ffffff !important;
}
.AccordionPersoTypo {
  margin-top: 10px !important;
}
.accordianstyle {
  background-color: #ffffff !important;
  width: 93.7% !important;
  margin-left: 14px !important;
  border-radius: 10px !important;
  margin-top: 15px !important;
}
.PersonalContainer2 {
  padding-top: 13px !important;
  padding-left: 20px !important;
}
.lightGreyColorTypo {
  color: #b2b1b2 !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 700 !important;
}
.ArrowIcon {
  color: rgba(5, 37, 76, 1) !important;
}
.swiper {
  width: 100%;
  height: 100%;
}
.SwiperSlide-style {
  margin-top: 5px !important;
  cursor: pointer !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-pagination-bullet-active {
  background: #c850c0 !important;
}
.swiper-pagination {
  display: none !important;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .swiper-button-prev {
  visibility: hidden;
} */

.swiper-button-next,
.swiper-button-prev {
  color: gray !important;
}
.mySwiper {
  padding: 0px 25px !important;
}

.plat .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
/* Privacy Policy */
.link-style {
  text-decoration: none !important;
  text-transform: none !important;
}
.link-style-color {
  color: #fff !important;
  text-decoration: none !important;
  text-transform: none !important;
}
@media only screen and (max-width: 600px) {
  .homeaboutcynetixbtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border: 1px solid rgba(255, 255, 255, 1) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 3px 8px !important;
    right: 39px !important;
    font-size: 10px !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .homeaboutcynetixbtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 3px 9px !important;
    right: 29px !important;
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 600px) {
  .homeaboutcynetixbtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border: 1px solid rgba(255, 255, 255, 1) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 6px 22px !important;
    text-decoration: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .googleplaybtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border: 1px solid rgba(255, 255, 255, 1) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 5px 29px !important;
    right: 29px !important;
    font-size: 8px !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .googleplaybtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 3px 12px !important;
    right: 29px !important;
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 600px) {
  .googleplaybtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border: 1px solid rgba(255, 255, 255, 1) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 10px 18px !important;
    text-decoration: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .googleplaybtn {
    background: radial-gradient(
      144.28% 144.28% at -16.83% -15.93%,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0.04) 100%
    ) !important;
    border-radius: 8px !important;
    letter-spacing: 0.25em !important;
    text-align: center !important;
    text-transform: capitalize !important;
    padding: 19px 88px !important;
    right: 29px !important;
    font-size: 8px !important;
  }
}
